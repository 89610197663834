import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import CTA from '../components/button';

const Page = props => (
  <Layout>
    <div>
      <Row className="heading" middle="xs" center="xs">
        <Col xs={10}>
          <h2 className="section-heading scroll">Work Samples</h2>
          <Row start="xs">
            <p>Hey There!</p>
            <p>
              At the moment I have 3 products I plan to sell:
              <a href="/shop/noma">Noma (font)</a>, <a href="/shop/endure">Endure (font)</a>, and NEW{' '}
              <a href="/shop/vectorglitch">VectorGlitch (Graphic Styles for Illustrator)</a>. These products don't
              necessarily reflect my past works, but I've included the following samples to demonstrate my overall
              skillset. Thanks for looking!
            </p>
            <br />
          </Row>
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={10}>
          <h2>Illustration:</h2>
          <p>Spinturnix Banner Illustration</p>
          <Img fluid={props.data.illustration.childImageSharp.fluid} />
          <br />
          <p>Illustration for a Startup Agency</p>
          <Img fluid={props.data.akomplice.childImageSharp.fluid} />
          <br />
          <p>Poster for Local Agency</p>
          <Img fluid={props.data.digital.childImageSharp.fluid} />
          <br />
          <p>Poster Illustration - Personal Project</p>
          <Img fluid={props.data.traveler.childImageSharp.fluid} />
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={10}>
          <h2>Web Design:</h2>
          <p>Expanse - Custom Shopify Theme</p>
          <Img fluid={props.data.expanse.childImageSharp.fluid} />
          <br />
          <p>Impatient Cow Video Production - WordPress Theme</p>
          <Img fluid={props.data.icow.childImageSharp.fluid} />
          <br />
          <p>KBAuthority - Website Redesign</p>
          <Img fluid={props.data.kba.childImageSharp.fluid} />
          <br />
          <p>Loveland Innovations Home Page Redesign</p>
          <Img fluid={props.data.loveland.childImageSharp.fluid} />
          <br />
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={10}>
          <h2>Branding:</h2>
          <br />
          <p>Riverton City - Logo Design Submission</p>
          <Img fluid={props.data.riverton2.childImageSharp.fluid} />
          <br />
          <p>Spinturnix - Logo + Service Icons</p>
          <Img fluid={props.data.spinturnix.childImageSharp.fluid} />
          <br />
          <p>Copacabana Logo Design</p>
          <Img fluid={props.data.copacabana.childImageSharp.fluid} />
        </Col>
      </Row>
      <Row center="xs">
        <h2>Thanks for looking!</h2>
      </Row>
    </div>
  </Layout>
);

Page.displayName = 'Product Page';
export default Page;

export const pageQuery = graphql`
  query {
    expanse: file(relativePath: { eq: "portfolio/Expanse-Shopify-Theme-Home.jpg" }) {
      ...fluidImage
    }
    icow: file(relativePath: { eq: "portfolio/ICOW-Home-Page.jpg" }) {
      ...fluidImage
    }
    kba: file(relativePath: { eq: "portfolio/KBAuthority-Home-Page.jpg" }) {
      ...fluidImage
    }
    loveland: file(relativePath: { eq: "portfolio/Loveland-Innovations-Home-Page.png" }) {
      ...fluidImage
    }
    riverton2: file(relativePath: { eq: "portfolio/Riverton-City-Logo-2.png" }) {
      ...fluidImage
    }
    copacabana: file(relativePath: { eq: "portfolio/Copacabana-Logo.png" }) {
      ...fluidImage
    }
    illustration: file(relativePath: { eq: "portfolio/Spinturnix-Hero-Illustration.jpg" }) {
      ...fluidImage
    }
    traveler: file(relativePath: { eq: "portfolio/The-Traveler-Poster.jpg" }) {
      ...fluidImage
    }
    akomplice: file(relativePath: { eq: "portfolio/Akomplice-Illustration.png" }) {
      ...fluidImage
    }
    digital: file(relativePath: { eq: "portfolio/Digital-Poster.jpg" }) {
      ...fluidImage
    }
    spinturnix: file(relativePath: { eq: "portfolio/Spinturnix-Logo-Icons.jpg" }) {
      ...fluidImage
    }
  }
`;
